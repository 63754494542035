.table-container {
  padding-bottom: 10px;
  position: relative;
  /* min-height: 100vh; */
  overflow-x: auto;
  min-width: 90vw;
  border-radius: 10px;
  border: 1px solid rgb(192, 192, 192);
}
.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

.table__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
