.filters {
  border: 1px solid rgb(192, 192, 192);

  border-radius: 5px;
  padding: 20px;
  margin-bottom: 40px;
}
.btn__container {
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.filters-container {
  display: grid;
  grid-template-columns: 8fr 2fr;
  gap: 20px;
}
@media screen and (max-width: 1100px) {
  .filters-container {
    display: block;
  }
  .filters__col--2 {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }

  .btn__container {
    transform: translateY(-10px);
  }
}
@media screen and (max-width: 850px) {
  .filters__col--1 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (max-width: 550px) {
  .filters__col {
    display: flex !important;
    flex-direction: column;
  }
  .btn__container {
    transform: translateY(0);
  }
}
.filters__col--1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
}

.filters__col--2 > *:not(:last-child) {
  margin-bottom: 10px;
}

.filters__sorting {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.filters__minmax {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}
.searchbox {
  display: grid;
  grid-template-columns: 3fr 7fr;
  gap: 10px;
}
