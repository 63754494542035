.table__category {
  /* background-color: hsl(0, 0%, 88%); */
  background-color: hsl(212, 80%, 80%);

  /* color: black; */
  color: hsl(212, 80%, 30%);
  padding: 5px;
  display: inline-block;
  margin: 5px;
  font-size: 12px;
  border-radius: 2px;
}
