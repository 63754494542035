.model-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.model-container {
  background-color: #fff;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.input-group-3-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.input-group-2-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.input__group {
  display: flex;
  flex-direction: column;
}
.model__label {
  margin-bottom: 5px;
}
.model__input {
  height: 30px;
  margin-bottom: 10px;
}
.model__title {
  margin-bottom: 20px;
  font-size: 30px;
  color: #1565c0;
}
.model__btn {
  background-color: #1565c0;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  float: right;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
}
