@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Roboto";
}
html {
}
body {
  font-family: "Roboto";
  /* padding: 20px; */
}
