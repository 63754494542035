.login {
  background-color: #87bf68;
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 0 10px;
}

.form {
  background-color: white;
  max-width: 400px;
  width: 90vw;
  border-radius: 3px;
  padding: 50px;
}
@media screen and (max-width: 500px) {
  .form {
    padding: 30px;
  }
}
.form__input-group {
  position: relative;
  margin-bottom: 20px;
}
.input-field {
  display: block;
  background-color: hsl(0, 0%, 95%);
  border: none;
  height: 50px;
  width: 100%;
  border-radius: 3px;
  padding: 0 15px;
  font-size: 16px;
  outline: none;
}
.input-field::placeholder {
  color: hsl(0, 0%, 65%);
  text-transform: capitalize;
}
.input-field:focus {
  border: 2px solid #87bf68;
}
.input-field--invalid {
  border: 2px solid red !important;
}

.form__error {
  margin-bottom: 5px;
  color: red;
  font-size: 12px;
}
.button {
  width: 100%;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
}
.button--green {
  /* margin-top: 12px; */
  background-color: #87bf68;
  color: white;
}
.button--green:hover {
  background-color: hsl(99, 40%, 48%);
}

.error-message {
  border-radius: 3px;
  color: hsl(0, 100%, 70%);
  text-align: center;
  margin-bottom: 20px;
}
