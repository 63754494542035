td,
th {
  text-align: left;
  cursor: default;
  padding: 15px;
  /* border-bottom: 1px solid hsl(0, 0%, 83%); */
}
td {
  border: 1px solid hsl(0, 0%, 93%);
}
th {
  background-color: hsl(212, 80%, 50%);
  color: white;
  text-transform: uppercase;
}
tr:hover {
  background-color: hsl(207, 90%, 90%) !important;
}
tr:nth-child(even) {
  background-color: hsl(0, 0%, 95%);
}
tr {
  background-color: white;
}
.row__null {
  text-align: center !important;
}

.table__url {
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
}
.table__url:hover {
  text-decoration: underline;
}
.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip:hover .tooltip-text {
  opacity: 1;
}
.tooltip-text {
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
  left: 0;
  top: -100%;
  background-color: #0066cc;
  border-radius: 3px;
  color: white;
  padding: 5px;
  font-size: 12px;
}
.tooltip-text::after {
  content: "";
  background-color: inherit;
  height: 8px;
  width: 8px;
  position: absolute;
  left: -6px;
  top: 45%;
  transform: translateY(-50%);
  z-index: -1;
  rotate: 45deg;
}
.actions--hidden {
  visibility: hidden;
}
